import React from "react";
import { Card } from 'antd';
import { PieChart, Pie, Cell, Tooltip, Legend } from 'recharts';
import { useQuery } from '@tanstack/react-query';
import { request } from "../../../../request/request.ts";
import { useRoute } from "wouter";

interface SnackVoteAnalyticsResponse {
    snackId: string;
    snackName: string;
    imageUrl: string;
    totalVotes: number;
    voteTypeCount: Record<string, number>; // Здесь voteType будет ключом, например: { LIKE: 10, DISLIKE: 5, SKIP: 3 }
}

function useGameAnalytics(gameId: string) {
    return useQuery({
        queryKey: ['analytics', gameId],
        queryFn: () =>
            request<SnackVoteAnalyticsResponse[]>({
                endpoint: `admin/analytics/game/${gameId}`,
            }),
    });
}

export function GameAnalyticsPage() {
    const [match, params] = useRoute("/analytics/:gameId");
    const gameId = params?.gameId;

    if (!match || !gameId) {
        return <div>Error: No game ID provided</div>;
    }

    const { data, isLoading, error } = useGameAnalytics(gameId);

    if (isLoading) return <div>Loading...</div>;
    if (error) return <div>Error loading analytics</div>;


    if (!data || data.length === 0) {
        return <div>No data available</div>;
    }

    const colors = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];
    const totalVotesForGame = data.reduce((total, snack) => total + snack.totalVotes, 0);
    return (
      <div>
          {/* Информация об игре по центру */}
          <div style={{ textAlign: 'center', marginBottom: '20px' }}>
              <h2 style={{ fontWeight: 'bold' }}>Game Analytics</h2>
              <p>Total Votes: {totalVotesForGame}</p>
          </div>

          {data.map((snack, _) => (
            <Card key={snack.snackId} style={{ marginBottom: '20px' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>

                    <div style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <img
                          src={snack.imageUrl}
                          alt="Snack Image"
                          style={{ width: '200px', height: '200px', objectFit: 'cover' }}
                        />
                    </div>
                    <div style={{ flex: 1 }}>
                        <h3 style={{ fontWeight: 'bold', textAlign: 'center' }}>{snack.snackName}</h3>
                        <p style={{ textAlign: 'center' }}>Total Votes: {snack.totalVotes}</p>
                        <PieChart width={400} height={400}>
                            <Pie
                              data={Object.entries(snack.voteTypeCount).map(([key, value]) => ({ name: key, value }))}
                              cx="50%"
                              cy="50%"
                              outerRadius={150}
                              label={(entry) => `${entry.name}: ${entry.value}`}
                              dataKey="value"
                            >
                                {Object.entries(snack.voteTypeCount).map((_, index) => (
                                  <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                                ))}
                            </Pie>
                            <Tooltip />
                            <Legend />
                        </PieChart>
                    </div>
                </div>
            </Card>
          ))}
      </div>
    );
}
