import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

interface Props {
    id: string; //UUID
    src: string;
    label?: string;
    mini?: boolean;
}

export const ImageWithSpinner: React.FC<Props> = (props) => {

    const { id, src, label } = props;
    const mini = props.mini ?? false;
    const [loaded, setLoaded] = useState(false);
    const ref = useRef<HTMLImageElement>(null);

    const gradientColor = useMemo(() => resolveGradientColor(id), [id]);

    useEffect(() => {
        const img = ref.current;
        if (img) {
            let mounted = true;
            img.src = src;
            const handleLoad = () => {
                if (mounted) {
                    setLoaded(true);
                }
            };
            const handleError = () => {
                // TODO: ?
            };
            img.onload = handleLoad;
            img.onerror = handleError;
            return () => {
                mounted = false;
                img.onload = null;
                img.onerror = null;
            };
        }
    }, [ref]);

    return <div
        className={`w-full mx-auto h-full overflow-hidden shadow-2xl relative`}
        style={{ borderRadius: mini ? '16px' : '64px' }}
    >
        <div className="h-full w-full absolute inset-0" style={{backgroundColor: gradientColor}} />
        {!loaded && <Spin
            indicator={<LoadingOutlined spin />}
            size="large"
            className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
        />}
        <img ref={ref} draggable={false}
             className="select-none [user-drag:none] h-full w-full max-w-full object-cover object-center relative" alt={label} />
        {loaded && <div className="absolute inset-0"
                        style={{ background: `linear-gradient(to bottom, transparent 50%, ${gradientColor} 80%)` }} />}
        {label &&
            <h2 className="text-white text-6xl leading-12 font-bold text-center absolute bottom-40 left-1/2 transform -translate-x-1/2 w-full p-8">
                {label.toLowerCase()}
            </h2>}
    </div>;
};

function resolveGradientColor(snackUuid: string): string {
    const options = ['#90A914', '#FF6725', '#0F8CFF', '#A339B9', '#D73511'];
    const hash = hashString(snackUuid);
    const index = Math.abs(hash) % options.length;
    return options[index];
}

function hashString(str: string): number {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        const chr = str.charCodeAt(i);
        hash = (hash << 5) - hash + chr;
        hash |= 0;
    }
    return hash;
}
