import { Button, Form, Input, message, Modal, Select, Upload, UploadProps } from "antd";
import React, { useEffect, useState } from "react";
import { AddSnackRequest } from "../../../../../models/snack.ts";
import { UploadOutlined } from "@ant-design/icons";
import { useAddSnackMutation, useGamesForAdmin } from "../hooks";
import SnackImageCardFromBlob from "./snackImageCardFromBlob.tsx";

interface OwnProps {
  gameIdFilter?: string;
  open: boolean;
  onClose: () => void;
}

export default function AddSnackModal({ gameIdFilter, open, onClose }: OwnProps) {
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState<any[]>([]);
  const addMutation = useAddSnackMutation();

  const handleOk = () => {
    form
      .validateFields()
      .then((values: AddSnackRequest) => {
        if (fileList.length === 0) {
          message.error("Please upload an image for the snack");
          return;
        }

        const formData = new FormData();
        formData.append("snackRequest", new Blob([JSON.stringify(values)], { type: "application/json" }));
        formData.append("file", fileList[0].originFileObj);

        addMutation.mutate(formData);
        onClose();
      })
      .catch(() => {
        message.error("Please fill out the form correctly");
      });
  };

  // Upload Props
  const uploadProps: UploadProps = {
    beforeUpload: () => false, // Prevent automatic upload
    maxCount: 1,
    fileList: fileList,
    onChange: ({ fileList }) => setFileList(fileList),
    accept: "image/*",
    showUploadList: false // We'll render the list ourselves
  };

  const { data: gamesData } = useGamesForAdmin();

  const addSnackGameOptions = gamesData?.content?.map((game) => ({
    label: game.publicId,
    value: game.id
  }));

  const _gameIdFilter = addSnackGameOptions?.find((game) => game.label === gameIdFilter)?.value;

  useEffect(() => {
    if (open) {
      form.resetFields();
      setFileList([]);
    }
  }, [open]);

  return (
    <Modal
      title="Add Snack"
      open={open}
      onOk={handleOk}
      onCancel={onClose}
      destroyOnClose
      className="modal-custom"
      okButtonProps={{ className: "bg-blue-500 hover:bg-blue-600 border-none" }}
      cancelButtonProps={{ className: "hover:border-gray-300" }}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          name="name"
          label="Snack Name"
          rules={[{ required: true, message: "Please input the snack name" }]}
          className="mb-4"
        >
          <Input className="rounded-md" />
        </Form.Item>
        <Form.Item
          name="gameId"
          label="Game"
          rules={[{ required: true, message: "Please select a game" }]}
          className="mb-4"
          initialValue={_gameIdFilter}
        >
          <Select
            showSearch
            placeholder="Select a game"
            optionFilterProp="label"
            options={addSnackGameOptions}
            filterOption={(input, option) =>
              option?.label.toLowerCase().includes(input.toLowerCase()) ?? false
            }
            className="w-full"
          />
        </Form.Item>
        <Form.Item
          label="Image"
          rules={[{ required: true, message: "Please upload an image" }]}
          className="mb-0"
        >
          <Upload {...uploadProps}>
            <Button
              className="mb-4"
              icon={<UploadOutlined />}
            >Select Image</Button>
          </Upload>
          {fileList.length > 0 && (
            <SnackImageCardFromBlob file={fileList[0]}/>
          )}
        </Form.Item>
      </Form>
    </Modal>
  );
}
