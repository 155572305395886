type OfficeLocation = {label: string, id: string, disabled?: boolean};

export const defaultLocations: OfficeLocation[] = [
    {label: "Amsterdam", id: "amsterdam"},
    {label: "Munich", id: "munich"},
    {label: "Berlin", id: "berlin"},
    {label: "Belgrade", id: "belgrade"},
    {label: "Paphos", id: "paphos"},
    {label: "Yerevan", id: "yerevan", disabled: true},
];
