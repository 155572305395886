import { Route, Router, Switch, useLocation } from "wouter";
import GamesPage from "./components/games/gamesPage.tsx";
import { NavBar } from "./components/navBar.tsx";
import LoginButton from "./components/login/loginButton.tsx";
import { useMe } from "../../hooks/useMe.ts";
import LogoutButton from "./components/login/logoutButton.tsx";
import { RolesPage } from "./components/users/manageUsersPage.tsx";
import SnacksPage from "./components/snacks/snacksPage.tsx";
import { GameAnalyticsPage } from "./components/analytics/GameAnalyticsPage.tsx";

export default function AdminPage() {
  const [, navigate] = useLocation();
  const { authorized, isAdmin } = useMe();

  if (!isAdmin) {
    navigate("/");
  }

  return (
    <>
      <div>
        <div className="absolute top-4 right-4">
          <div className="flex flex-col gap-1">
            <button
              className="border-2 p-1 text-center"
              onClick={() => window.location.href = "/"}>
              Main page
            </button>
            {authorized ? <LogoutButton/> : <LoginButton />}
          </div>
        </div>
        <h1 className="text-3xl font-bold mb-8 text-center">Administration Page</h1>
      </div>
      <div className="mb-8">
        <NavBar />
      </div>
      <Router base="/admin">
        <Switch>
          <Route path="/" component={GamesPage} />
          <Route path="/snacks/:id" component={SnacksPage} />
          <Route path="/snacks" component={SnacksPage} />
          <Route path="/games" component={GamesPage} />
          <Route path="/users" component={RolesPage} />
          <Route path="/analytics/:gameId" component={GameAnalyticsPage} />
          <Route>
            {() => <div>Page not found</div>}
          </Route>
        </Switch>
      </Router>
    </>
  );
}
