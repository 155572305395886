const LoginButton = () => {
  const handleLogin = () => {
    const currentUrl = window.location.href;
    window.location.href = `/login/oauth2/authorization/google?successUrl=${encodeURIComponent(currentUrl)}`;
  };

  return (
    <button
      className="border-2 p-1 text-center"
      onClick={handleLogin}
    >
      Login
    </button>
  );
};

export default LoginButton;
