import { serializeQuery } from '../utils/query.ts';

export interface IRequestConfig {
    endpoint: string;
    query?: Record<string, any>;
    body?: any;
    method?: 'PATCH' | 'GET' | 'POST' | 'DELETE' | 'PUT';
    headers?: Record<string, string>;
}

const isDev = Boolean(import.meta.env.DEV);

function toBody(data: any | null | undefined) {
    if (data === null || data === undefined) return undefined;
    return data instanceof FormData ? data : JSON.stringify(data);
}

export async function request<TData = any>(config: IRequestConfig): Promise<TData> {
    const delayPromise = delay(250);
    const resultPromise = fetch(`/api/${config.endpoint}?${serializeQuery(config.query)}`, {
        headers: config.headers ?? {
            'Content-Type': 'application/json',
        },
        body: toBody(config.body),
        method: config.method || 'GET',
        redirect: isDev ? 'manual' : undefined,
    }).then(async res => {
        if (isDev && res.type === 'opaqueredirect') {
            console.error('Probably you are using dev server and forgot to login on backend :8080 first.');
            window.location.href = 'http://localhost:8080/';
            return new Promise(() => {});
        }

        if (res.headers.get('Content-Type') !== 'application/json') {
            return res.ok ? undefined : res.text().then(e => Promise.reject(e));
        }

        try {
            const result = await res.json();

            if (res.ok) return result;
            return Promise.reject(result);
        } catch (e) {
            return Promise.reject(new Error('Response not looks like json'));
        }
    });

    // add delay for better UI
    return Promise.all([delayPromise, resultPromise]).then(([, r]) => r);
}

const delay = (ms = 300) => new Promise(rs => setTimeout(rs, ms));
