import React, { useEffect } from "react";
import { useLocation, useParams } from "wouter";
import { SnackCard } from "./components/snackCard.tsx";
import { VoteType } from "../../models/vote-type.ts";
import { Header } from "./components/header.tsx";
import { ImageWithSpinner } from "./components/imageWithSpinner.tsx";
import { useSnacksForGame } from "./hooks";
import { request } from "../../request/request.ts";
import { SnackDTO } from "../../models/snack.ts";


export const GamePage: React.FC = () => {
  const [, setLocation] = useLocation();
  const [visibleCards, setVisibleCards] = React.useState<SnackDTO[]>([]);
  const [isInitialized, setIsInitialized] = React.useState(false);
  const [isRefetching, setIsRefetching] = React.useState(false);

  const params = useParams();
  const gameName = params.name!;

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    status,
    error,
    refetch,
  } = useSnacksForGame(gameName);

  // Ensure at least 3 cards are loaded on initial load and whenever data changes
  useEffect(() => {
    const totalCards = visibleCards.length;
    const loadedSnacksCount = data?.pages.flatMap((page) => page.content).filter(Boolean).length || 0;
    const limiter = Math.min(3, loadedSnacksCount);

    if (limiter == 0 || (totalCards === 0 && (status === "pending" || status === "error"))) {
      return;
    } else if (totalCards === 0 && status === "success" && !isInitialized) {
      const snacks = data?.pages
        .flatMap((page) => page.content)
        .filter(Boolean) || [];
      setVisibleCards(snacks);
      setIsInitialized(true);
    } else if (totalCards < 3 && hasNextPage && !isFetchingNextPage) {
      fetchNextPage().then(({ data }) => {
        // Flatten the pages and filter out duplicates
        const snacks = data?.pages
          .flatMap((page) => page.content)
          .filter((snack) => visibleCards.every((card) => card.id !== snack.id)) || [];
        setVisibleCards((prev) => [...prev, ...snacks]);
      });
    } else if (totalCards < limiter && !hasNextPage && !isRefetching) {
      setIsRefetching(true);
      refetch().then(({ data }) => {
        const snacks = data?.pages
          .flatMap((page) => page.content)
          .filter((snack) => visibleCards.every((card) => card.id !== snack.id)) || [];
        setVisibleCards((prev) => [...prev, ...snacks]);
      }).finally(() => setIsRefetching(false));
    }
  }, [data, hasNextPage, isFetchingNextPage, visibleCards, fetchNextPage, status, refetch, isInitialized, isRefetching]);

  const currentSnack = visibleCards[0] ?? null;
  const bgSnack1 = visibleCards[1] ?? null;
  const bgSnack2 = visibleCards[2] ?? null;

  // const repeatingSnacks = currentSnack?.votedType === "SKIP"; // TODO: inform user that this one was skipped before

  if (error) {
    return <div className="flex flex-nowrap flex-col items-center justify-center h-screen text-center">
      <h1 className="font-bold text-8xl">{error.name}</h1>
      <p className="my-4 text-2xl">{error.message} 💀</p>
      <button
        onClick={() => setLocation("")}
        className="rounded-full bg-gray-100 hover:bg-gray-200 focus:bg-gray-200 px-16 py-2 text-xl"
      >
        Back to home
      </button>
    </div>;
  }

  const vote = async (type: VoteType) => {
    if (!currentSnack) {
      throw new Error();
    }
    const gameId = currentSnack.gameId;
    const snackId = currentSnack.id;
    try {
      await request({
        endpoint: "votes",
        method: "POST",
        body: {
          gameId,
          snackId,
          type
        }
      });
      setVisibleCards((prev) => prev.slice(1));
    } catch (e: any) {
      if (e.status === 401) {
        window.location.reload();
      }
    }
  };

  return <div className="flex flex-col flex-nowrap items-center" style={{ height: "100svh" }}>
    <Header gameName={gameName} rightButtonType="history" />

    {currentSnack === null && status === "success" &&
      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center">
        <img
          src="https://media.tenor.com/qJRMLPlR3_8AAAAi/maxwell-cat.gif"
          alt="Maxwell cat"
        />
        <p>That's it for now!</p>
        <p>Please come again later for more holywars!</p>
      </div>
    }

    {currentSnack !== null &&
      <div className="pt-10 pb-4 flex-grow h-full w-full" style={{ height: "100svh" }}>
        <div className="h-full w-full max-w-[600px] relative mx-auto" id="stack">
          {bgSnack2 && <div className="absolute top-[-60px] left-0 z-10 h-full w-full opacity-40" id="stack-3">
            <ImageWithSpinner id={bgSnack2.id} src={bgSnack2.imageUrl} label={bgSnack2.name} key={bgSnack2.imageUrl} />
          </div>}

          {bgSnack1 && <div className="absolute top-[-30px] left-0 z-20 h-full w-full opacity-70" id="stack-2">
            <ImageWithSpinner id={bgSnack1.id} src={bgSnack1.imageUrl} label={bgSnack1.name} key={bgSnack1.imageUrl} />
          </div>}

          <div className="relative z-30 h-full" id="stack-1">
            <SnackCard
              id={currentSnack.id}
              key={currentSnack.id}
              label={currentSnack.name}
              imageUrl={currentSnack.imageUrl}
              onSwipeRight={() => vote("LIKE")}
              onSwipeLeft={() => vote("NO")}
              onSwipeDown={() => vote("SKIP")}
              onSwipeUp={() => vote("SUPERLIKE")}
            />
          </div>
        </div>
      </div>
    }

  </div>;

};
