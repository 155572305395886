export function serializeQuery(query: object | undefined) {
    if (!query) return '';
    const params = new URLSearchParams();
    for (const param in query) {
        if (query[param] === undefined || query[param] === null) {
            continue;
        }
        params.set(param, query[param]);
    }

    return params.toString();
}
