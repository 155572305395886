import React, { useEffect, useState } from "react";
import { Button, Form, Input, message, Modal, QRCode, Table, Typography } from "antd";
import { BarChartOutlined, DeleteOutlined, EditOutlined, PlusOutlined, QrcodeOutlined } from "@ant-design/icons";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { request } from "../../../../request/request.ts";
import { Link, useLocation } from "wouter";
import { PagedContent } from "../../../../models";
import { GameDTO } from "../../../../models/game.ts";

function useGamesForAdmin(currentPage: number, pageSize: number) {
  return useQuery({
    queryKey: ["admin", "games", currentPage, pageSize],
    queryFn: () =>
      request<PagedContent<GameDTO>>({
        endpoint: "admin/games",
        query: {
          page: currentPage - 1,
          perPage: pageSize
        }
      })
  });
}

function useAddGameMutation() {
  const qc = useQueryClient();
  return useMutation({
    mutationFn: (game: Partial<GameDTO>) =>
      request({
        endpoint: "admin/games",
        method: "POST",
        body: game
      }),
    onSuccess: async () => {
      await qc.invalidateQueries({ queryKey: ["admin", "games"] });
      message.success("Game added successfully");
    },
    onError: () => message.error("Failed to add game")
  });
}

function useUpdateGameMutation() {
  const qc = useQueryClient();
  return useMutation({
    mutationFn: (game: GameDTO) =>
      request({
        endpoint: `admin/games`,
        method: "PUT",
        body: game
      }),
    onSuccess: async () => {
      await qc.invalidateQueries({ queryKey: ["admin", "games"] });
      message.success("Game updated successfully");
    },
    onError: () => message.error("Failed to update game")
  });
}

function useDeleteGameMutation() {
  const qc = useQueryClient();
  return useMutation({
    mutationFn: (game: Partial<GameDTO>) =>
      request({
        endpoint: `admin/games`,
        method: "DELETE",
        body: game
      }),
    onSuccess: async () => {
      await qc.invalidateQueries({ queryKey: ["admin", "games"] });
      message.success("Game deleted successfully");
    },
    onError: () => message.error("Failed to delete game")
  });
}

export default function GamesPage() {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [isQrModalVisible, setIsQrModalVisible] = useState<boolean>(false);
  const [editingGame, setEditingGame] = useState<GameDTO | null>(null);
  const [form] = Form.useForm();
  const [, setLocation] = useLocation(); // Хук для навигации

  const { data: pagedResponse, isLoading, error } = useGamesForAdmin(currentPage, pageSize);
  const addMutation = useAddGameMutation();
  const updateMutation = useUpdateGameMutation();
  const deleteMutation = useDeleteGameMutation();

  const showAddModal = () => {
    setEditingGame(null);
    form.resetFields();
    setIsModalVisible(true);
  };

  const showEditModal = (game: GameDTO) => {
    setEditingGame(game);
    form.setFieldsValue(game);
    setIsModalVisible(true);
  };

  const showQrCodeModal = (game: GameDTO) => {
    setEditingGame(game);
    setIsQrModalVisible(true);
  };

  const handleDelete = (publicId: string) => {
    Modal.confirm({
      title: "Are you sure you want to delete this game?",
      onOk: () => deleteMutation.mutate({ publicId })
    });
  };

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        if (editingGame) {
          updateMutation.mutate({ ...editingGame, ...values });
        } else {
          addMutation.mutate(values);
        }
        setIsModalVisible(false);
      })
      .catch(async () => {
        message.error("Please fill out the form correctly");
      });
  };

  useEffect(() => {
    if (error && !isLoading) {
      message.error("Failed to fetch games");
    }
  }, [error, isLoading]);

  const handleNavigateToAnalytics = (gameId: string) => {
    setLocation(`/analytics/${gameId}`);
  };

  // Generate publicId from name
  const generatePublicId = (name: string): string => {
    let validNamePart = name.replace(/[^a-zA-Z0-9\-\s]/g, "");
    validNamePart = validNamePart.replace(/\s/g, "_");
    const uniqueHash = generateUniqueHash(10);
    let validName = `${validNamePart}-${uniqueHash}`;
    // remove duplicate dashes
    validName = validName.replace(/--+/g, "-");
    // remove duplicate underscores
    validName = validName.replace(/__+/g, "_");
    // remove leading dashes and underscores
    validName = validName.replace(/^[\-_]+/, "");
    return validName;
  };

  // Generate a unique hash
  const generateUniqueHash = (length: number): string => {
    const chars = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let result = "";
    for (let i = 0; i < length; i++) {
      result += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return result;
  };

  // Update publicId when name changes
  const handleFormValuesChange = (changedValues: any, _: any) => {
    if (changedValues.name) {
      const name = changedValues.name;
      const publicId = generatePublicId(name);
      form.setFieldsValue({ publicId });
    }
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      className: "font-semibold",
      render: (text: string, record: GameDTO) => (
        <Link to={`/snacks/${record.publicId}`} className="text-black hover:text-blue-700 hover:underline">
          {text}
        </Link>
      )
    },
    {
      title: "Public ID",
      dataIndex: "publicId",
      key: "publicId",
      className: "text-gray-500"
    },
    {
      title: "Actions",
      key: "actions",
      render: (_: any, record: GameDTO) => (
        <div className="flex space-x-2">
          <EditOutlined
            className="text-blue-500 hover:text-blue-700 cursor-pointer text-xl"
            onClick={() => showEditModal(record)}
          />
          <DeleteOutlined
            className="text-red-500 hover:text-red-700 cursor-pointer text-xl"
            onClick={() => handleDelete(record.publicId)}
          />
          {/* Кнопка для перехода на аналитику */}
          <BarChartOutlined
            className="text-green-500 hover:text-green-700 cursor-pointer text-xl"
            onClick={() => handleNavigateToAnalytics(record.id)}
          />
          <QrcodeOutlined
            className="text-gray-500 hover:text-gray-700 cursor-pointer text-xl"
            onClick={() => showQrCodeModal(record)}
          />
        </div>
      )
    }
  ];

  return (
    <div className="p-4">
      <Button
        type="primary"
        icon={<PlusOutlined />}
        onClick={showAddModal}
        className="mb-4 bg-blue-500 hover:bg-blue-600 border-none"
      >
        Add Game
      </Button>
      <Table
        columns={columns}
        dataSource={pagedResponse?.content}
        loading={isLoading}
        pagination={{
          current: (pagedResponse?.page ?? 0) + 1,
          pageSize: pageSize,
          total: pagedResponse?.totalElements ?? 0,
          showSizeChanger: true,
          onChange: (page, pageSize) => {
            setCurrentPage(page);
            setPageSize(pageSize);
          }
        }}
        rowKey="id"
        className="shadow-sm"
      />
      <Modal
        title={editingGame ? "Edit Game" : "Add Game"}
        open={isModalVisible}
        onOk={handleOk}
        onCancel={() => setIsModalVisible(false)}
        destroyOnClose
        className="modal-custom"
        okButtonProps={{ className: "bg-blue-500 hover:bg-blue-600 border-none" }}
        cancelButtonProps={{ className: "hover:border-gray-300" }}
      >
        <Form
          form={form}
          layout="vertical"
          initialValues={editingGame || {}}
          onValuesChange={handleFormValuesChange}
        >
          <Form.Item
            name="name"
            label="Name"
            rules={[{ required: true, message: "Please input the game name" }]}
            className="mb-4"
          >
            <Input className="rounded-md" />
          </Form.Item>
          <Form.Item
            name="publicId"
            label="Public ID"
            className="mb-0"
          >
            <Input className="rounded-md" />
          </Form.Item>
        </Form>
      </Modal>
      {editingGame && <QrCodeModal
        game={editingGame}
        visible={isQrModalVisible}
        onClose={() => setIsQrModalVisible(false)}
      />}
    </div>
  );
};

function QrCodeModal({ game, visible, onClose }: { game: GameDTO, visible: boolean, onClose: () => void }) {
  const location = window.location.origin;
  const qrCodeUrl = `${location}/game/${game.publicId}`;

  return (
    <Modal
      title="Game QR Code"
      open={visible}
      onCancel={onClose}
      footer={null}
      className="modal-custom"
    >
      <div className="flex flex-col items-center">
        <QRCode
          value={qrCodeUrl}
          size={256}
          // Adjust colors as needed
          color="#000000"
          bgColor="#ffffff"
        />
        <Typography.Paragraph
          copyable
          className="mt-4 text-center break-all"
        >
          {qrCodeUrl}
        </Typography.Paragraph>
      </div>
    </Modal>
  );
}
