import React, { ReactNode, useState } from 'react';
import { VoteType } from '../../../models/vote-type.ts';
import { HeartFilled, QuestionCircleOutlined } from '@ant-design/icons';
import { LightningIcon, ThumbsDownIcon } from '@rescui/icons';

interface Props {
    voteId: string; //UUID
    value: VoteType;
    onChange(id: string, value: VoteType): void;
}

const VoteButton: React.FC<{type: VoteType, onClick(): void}> = (props) => <button onClick={props.onClick} className="w-10 h-10 text-m rounded-full bg-white hover:bg-gray-200 hover:scale-105 transform transition duration-300 ease-in-out flex flex-nowrap items-center justify-center">
    {resolveIcon(props.type)}
</button>;

export const VoteChangeButton: React.FC<Props> = (props) => {

    const {voteId, value, onChange} = props;
    const [expanded, setExpanded] = useState(false);

    function onNewValueSelected(value: VoteType): void {
        onChange(voteId, value);
        setExpanded(false);
    }

    return <div className="flex flex-nowrap gap-2">
        {expanded
            ? <>
                <VoteButton type="NO" onClick={() => onNewValueSelected("NO")}/>
                <VoteButton type="SUPERLIKE" onClick={() => onNewValueSelected("SUPERLIKE")}/>
                <VoteButton type="LIKE" onClick={() => onNewValueSelected("LIKE")}/>
            </>
            : <VoteButton type={value} onClick={() => setExpanded(!expanded)}/>
        }
    </div>;

};

function resolveIcon(type: VoteType): ReactNode {
    switch (type) {
        case "LIKE": return <HeartFilled style={{color: "#FF0200"}}/>;
        case "SUPERLIKE": return <LightningIcon size="s" style={{color: "#FFAF0D"}}/>;
        case "NO": return <ThumbsDownIcon size="s" style={{color: "#4A841C"}}/>;
        case "SKIP": return <QuestionCircleOutlined/>;
        default: return null;
    }
}
