import React, { useEffect, useState } from "react";
import { Button, message, Modal, Popover, Select, Table } from "antd";
import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { SnackDTO } from "../../../../models/snack.ts";
import { useRoute } from "wouter";
import AddSnackModal from "./components/addSnackModal.tsx";
import { useDeleteSnackMutation, useGamesForAdmin, useSnacksForAdmin } from "./hooks";
import BulkAddSnackModal from "./components/bulkAddSnackModal.tsx";
import EditSnackModal from "./components/editSnackModal.tsx";


export default function SnacksPage() {
  const [, params] = useRoute("/snacks/:id");
  const pathGameId = params?.id;

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [isBulkModalVisible, setIsBulkModalVisible] = useState<boolean>(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState<boolean>(false);
  const [gameIdFilter, setGameIdFilter] = useState<string | undefined>(pathGameId);
  const [editingSnack, setEditingSnack] = useState<SnackDTO | null>(null);

  // Fetch snacks data with pagination and gameId filter
  const { data: pagedResponse, isLoading, error } = useSnacksForAdmin(currentPage, pageSize, gameIdFilter);
  const deleteMutation = useDeleteSnackMutation();

  const handleDelete = (id: string) => {
    Modal.confirm({
      title: "Are you sure you want to delete this snack?",
      onOk: () => deleteMutation.mutate({ snackId: id })
    });
  };

  const showEditModal = (snack: SnackDTO) => {
    setEditingSnack(snack);
    setIsEditModalVisible(true);
  };

  useEffect(() => {
    if (error && !isLoading) {
      message.error("Failed to fetch snacks data");
    }
  }, [error, isLoading]);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      className: "font-semibold"
    },
    {
      title: "Game Internal ID",
      dataIndex: "gameId",
      key: "gameId",
      className: "text-gray-500"
    },
    {
      title: "Image",
      dataIndex: "imageUrl",
      key: "imageUrl",
      className: "text-gray-500",
      render: (text: string) => text ? (
        <Popover
          content={
            <img
              src={text}
              alt="Snack"
              className="w-[300px] h-[300px] border border-gray-300 shadow-lg transition-transform duration-300"
            />
          }
          trigger="hover"
          overlayStyle={{ padding: 0 }}
        >
          <img
            src={text}
            alt="Snack"
            className="w-[50px] h-[50px] transition-transform duration-300"
          />
        </Popover>
      ) : null
    },
    {
      title: "Actions",
      key: "actions",
      render: (_: any, record: SnackDTO) => (
        <div className="flex space-x-2">
          <EditOutlined
            className="text-blue-500 hover:text-blue-700 cursor-pointer text-xl"
            onClick={() => showEditModal(record)}
          />
          <DeleteOutlined
            className="text-red-500 hover:text-red-700 cursor-pointer text-xl"
            onClick={() => handleDelete(record.id)}
          />
        </div>
      )
    }
  ];

  // Fetch games for the gameId select field
  const { data: gamesData } = useGamesForAdmin();

  const tableFilterGameOptions = gamesData?.content?.map((game) => ({
    label: game.publicId,
    value: game.publicId
  }));

  return (
    <div className="p-4">
      <div className="flex justify-between mb-4">
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => setIsModalVisible(true)}
          className="bg-blue-500 hover:bg-blue-600 border-none"
        >
          Add Snack
        </Button>
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => setIsBulkModalVisible(true)}
          className="bg-green-500 hover:bg-green-600 border-none"
        >
          Add Multiple Snacks
        </Button>
        <div className="flex space-x-2">
          <Select
            placeholder="Filter by Game"
            className="w-60"
            allowClear
            showSearch
            optionFilterProp="label"
            options={tableFilterGameOptions}
            value={gameIdFilter}
            onChange={(value) => {
              setGameIdFilter(value);
              setCurrentPage(1);
            }}
          />
        </div>
      </div>
      <Table
        columns={columns}
        dataSource={pagedResponse?.content}
        loading={isLoading}
        pagination={{
          current: (pagedResponse?.page ?? 0) + 1,
          pageSize: pageSize,
          total: pagedResponse?.totalElements,
          showSizeChanger: true,
          onChange: (page, pageSize) => {
            setCurrentPage(page);
            setPageSize(pageSize);
          }
        }}
        rowKey="id"
        className="shadow-sm"
      />
      <AddSnackModal
        gameIdFilter={gameIdFilter}
        open={isModalVisible}
        onClose={() => setIsModalVisible(false)}
      />
      <BulkAddSnackModal
        gameIdFilter={gameIdFilter}
        open={isBulkModalVisible}
        onClose={() => setIsBulkModalVisible(false)}
      />
      {editingSnack && <EditSnackModal
        snack={editingSnack}
        open={isEditModalVisible}
        onClose={() => setIsEditModalVisible(false)}
      />}
    </div>
  );
};
