import {useState} from "react";
import {Button, Input, Table, type TableColumnsType, type TablePaginationConfig} from 'antd';
import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import {DeleteOutlined} from '@ant-design/icons';
import { request } from "../../../../request/request.ts";

export function RolesPage() {
  const [email, setEmail] = useState('');
  const {mutate, isPending, error: mutationError} = useSetRoleMutation();
  const {data: roles, isLoading, error: dataError} = useRolesForAdmin();

  const error = mutationError ?? dataError;

  return (
    <>
      <div className="mb-6 flex w-1/3 min-w-[400px] flex-col gap-2">
        <div className="flex gap-2">
          <Input
            autoFocus
            disabled={isLoading}
            value={email}
            onChange={e => setEmail(e.target.value)}
            placeholder="alexander.prendota@jetbrains.com"
          />
          <Button
            className="flex-1" type="primary"
            onClick={() => mutate({email, isAdmin: true})}
            loading={isPending}
          >
            Make an admin
          </Button>
        </div>
        {error ? (
          <div className="text-center text-red-500">
            {(error as any).status} {(error as any).responseMessage}
          </div>
        ) : null}
      </div>
      <Table pagination={pagination} rowKey="email" loading={isLoading} dataSource={roles} columns={columns}/>
    </>
  );
}

const pagination: TablePaginationConfig = {
  pageSize: 50,
};

const columns: TableColumnsType<RoleResponse> = [
  {
    dataIndex: 'email',
    title: 'email',
  },
  {
    dataIndex: 'name',
    title: 'name',
  },
  {
    dataIndex: '',
    title: '',
    align: 'right',
    render: (_, record) => <UnsetRole email={record.email}/>,
    width: 200,
  },
];

function useRolesForAdmin() {
  return useQuery({
    queryKey: ['admins', 'list'],
    queryFn: () =>
      request<RoleResponse[]>({
        endpoint: 'admin/roles',
      }),
  });
}

function UnsetRole({email}: { email: string }) {
  const {mutate, isPending} = useSetRoleMutation();

  return (
    <Button
      danger
      type="primary"
      shape="circle"
      loading={isPending}
      onClick={() => mutate({email, isAdmin: false})}
      icon={<DeleteOutlined/>}
    />
  );
}

function useSetRoleMutation() {
  const qc = useQueryClient();
  return useMutation({
    mutationFn: ({email, isAdmin}: { email: string; isAdmin: boolean }) =>
      request({
        endpoint: 'admin/roles/grant',
        method: 'POST',
        body: {
          email,
          isAdmin,
        },
      }),
    onSuccess: () => qc.invalidateQueries({queryKey: ['admins', 'list']}),
  });
}

interface RoleResponse {
  email: string;
  name: string;
}
