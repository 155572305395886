import { EditVoteRequest, Vote } from "../../../models/vote.ts";
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { request } from "../../../request/request.ts";
import { message } from "antd";
import { PagedContent } from "../../../models";
import { SnackDTO } from "../../../models/snack.ts";

async function fetchSnacks(pageParam: number, gameName?: string) {
  return await request<PagedContent<SnackDTO>>({
    endpoint: `snacks/game/${gameName}`,
    query: {
      page: pageParam,
      // perPage: 3
    }
  });
}

export function useSnacksForGame(gameName?: string) {
  return useInfiniteQuery({
    initialPageParam: 0,
    queryKey: ["snacks", gameName],
    queryFn: ({ pageParam }) => fetchSnacks(pageParam, gameName),
    getNextPageParam: (lastPage) => {
      if (lastPage.page + 1 < lastPage.totalPages) {
        return lastPage.page + 1;
      }
      return undefined;
    }
  });
}

export function useVotesForGame(gameName?: string) {
  return useQuery({
    queryKey: ["votes", gameName],
    queryFn: () => {
      if (gameName === undefined) {
        return [];
      }
      return request<Vote[]>({
        endpoint: `votes/game/${gameName}`
      });
    }
  });
}

export function useEditVoteMutation() {
  const qc = useQueryClient();
  return useMutation({
    mutationFn: (vote: EditVoteRequest) =>
      request({
        endpoint: "votes",
        method: "PUT",
        body: vote
      }),
    onSuccess: async () => {
      await qc.invalidateQueries({ queryKey: ["votes"] });
      message.success("Voted successfully");
    },
    onError: () => message.error("Failed to vote")
  });
}
