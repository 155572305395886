import React, { ReactNode } from 'react';
import "./iconButton.css";
import classNames from 'classnames';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    icon: ReactNode;
    onClick(): void;
    label?: string;
    expanded?: boolean;
    reversed?: boolean;
    activeColor?: string;
}

export const IconButton: React.FC<Props> = (props) => {

    const {icon, onClick, label, expanded, reversed, className, style, activeColor} = props;
    const expandable = label !== undefined && label.trim() !== "";

    const classname = classNames(`snackme-icon-button rounded-full bg-white hover:bg-gray-200 hover:scale-105 transform transition duration-300 ease-in-out flex flex-nowrap items-center justify-center text-xl ${className}`, {expanded, expandable, reversed});

    return <button
        onClick={onClick}
        className={classname}
        style={{color: activeColor ?? "black"}}
    >
        <span className="icon">{icon}</span>
        {label && <label className="snackme-icon-button-label text-black">{label}</label>}
    </button>;

};
