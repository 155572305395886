import React, { useEffect } from "react";
import { Header } from "./components/header.tsx";
import { useRoute } from "wouter";
import { ImageWithSpinner } from "./components/imageWithSpinner.tsx";
import { VoteChangeButton } from "./components/voteChangeButton.tsx";
import { VoteType } from "../../models/vote-type.ts";
import { useEditVoteMutation, useVotesForGame } from "./hooks";
import { message } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

export function HistoryPage() {
  const [, params] = useRoute("history/:name");
  const gameName = params?.name;

  const { data: votes, isLoading, error } = useVotesForGame(gameName);
  const updateVoteMutation = useEditVoteMutation();

  useEffect(() => {
    if (error && !isLoading) {
      message.error("Failed to fetch snacks data");
    }
  }, [error, isLoading]);

  const handleVoteChange = (voteId: string, value: VoteType) => {
    updateVoteMutation.mutate({ id: voteId, type: value });
  };

  if (isLoading) {
    return <LoadingOutlined
      className={"absolute top-1/2 left-1/2"}
      size={48}
    />;
  }

  return <div className="flex flex-col items-center">
    <Header gameName={gameName ?? "404"} rightButtonType="back-to-game" />

    {votes?.length === 0 &&
      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center">
        <img
          src="https://media.tenor.com/qJRMLPlR3_8AAAAi/maxwell-cat.gif"
          alt="Maxwell cat"
        />
        <p>Yooo man</p>
        <p>It's empty there, what's going on?</p>
      </div>
    }

    {votes && votes.length > 0 &&
      <div className="flex flex-wrap max-w-[600px] w-full">
        {votes.map(vote => <div key={vote.id} className="w-1/2 aspect-[2/3] p-2 relative">
          <ImageWithSpinner
            id={vote.snack.id}
            src={vote.snack.imageUrl}
            mini={true}
          />
          <label
            className="absolute bottom-10 left-0 text-white text-center w-full text-xl font-semibold overflow-hidden p-4 block">
            {vote.snack.name}
          </label>
          <div className="absolute right-8 top-8">
            <VoteChangeButton
              voteId={vote.id}
              value={vote.type}
              onChange={handleVoteChange}
            />
          </div>
        </div>)}
      </div>
    }
  </div>;

}
