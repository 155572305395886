import { request } from "../request/request.ts";
import { useQuery } from "@tanstack/react-query";

export function useMe() {
  const { data: user, isLoading, error } = useQuery({
    retry: false,
    queryKey: ['me'],
    queryFn: () =>
      request<{ email: string; isAdmin: boolean; }>({
        endpoint: 'users/me',
      }),
  });

  return {
    user,
    authorized: Boolean(user),
    isAdmin: Boolean(user?.isAdmin),
    isLoading,
    error,
  };
}
