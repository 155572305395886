export default function LogoutButton() {
  const handleLogout = () => {
    window.location.href = `/logout`;
  };
  return <button
    className="border-2 p-1 text-center"
    onClick={handleLogout}
  >
    Logout
  </button>;
}
