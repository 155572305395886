import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { request } from "../../../../../request/request.ts";
import { PagedContent } from "../../../../../models";
import { DeleteSnackRequest, EditSnackRequest, SnackDTO } from "../../../../../models/snack.ts";
import { message } from "antd";
import { GameDTO } from "../../../../../models/game.ts";

export function useSnacksForAdmin(currentPage: number, pageSize: number, gameId?: string) {
  return useQuery({
    queryKey: ['admin', 'snacks', currentPage, pageSize, gameId],
    queryFn: () => {
      if (gameId === undefined) {
        return {
          content: [],
          page: 0,
          totalElements: 0,
          totalPages: 0,
        }
      }

      return request<PagedContent<SnackDTO>>({
        endpoint: `admin/game/${gameId}`,
        query: {
          page: currentPage - 1,
          perPage: pageSize
        }
      });
    },
  });
}

export function useAddSnackMutation() {
  const qc = useQueryClient();
  return useMutation({
    mutationFn: (formData: FormData) =>
      request({
        endpoint: 'admin/snacks',
        method: 'POST',
        body: formData,
        headers: {},
      }),
    onSuccess: async () => {
      await qc.invalidateQueries({ queryKey: ['admin', 'snacks'] });
      message.success('Snack added successfully');
    },
    onError: () => message.error('Failed to add snack'),
  });
}

export function useUpdateSnackMutation() {
  const qc = useQueryClient();
  return useMutation({
    mutationFn: (snack: EditSnackRequest) =>
      request({
        endpoint: `admin/snacks`,
        method: "PUT",
        body: snack
      }),
    onSuccess: async () => {
      await qc.invalidateQueries({ queryKey: ["admin", "snacks"] });
      message.success("Snack updated successfully");
    },
    onError: () => message.error("Failed to update snack")
  });
}

export function useBulkAddSnacksMutation() {
  const qc = useQueryClient();
  return useMutation({
    mutationFn: (formData: FormData) =>
      request({
        endpoint: 'admin/snacks/bulk',
        method: 'POST',
        body: formData,
        headers: {},
      }),
    onSuccess: async () => {
      await qc.invalidateQueries({ queryKey: ['admin', 'snacks'] });
      message.success('Snacks added successfully');
    },
    onError: () => message.error('Failed to add snacks'),
  });
}

export function useDeleteSnackMutation() {
  const qc = useQueryClient();
  return useMutation({
    mutationFn: (snack: DeleteSnackRequest) =>
      request({
        endpoint: `admin/snacks`,
        method: 'DELETE',
        body: snack,
      }),
    onSuccess: async () => {
      await qc.invalidateQueries({ queryKey: ['admin', 'snacks'] });
      message.success('Snack deleted successfully');
    },
    onError: () => message.error('Failed to delete snack'),
  });
}

export function useGamesForAdmin() {
  return useQuery({
    queryKey: ['admin', 'games'],
    queryFn: () =>
      request<PagedContent<GameDTO>>({
        endpoint: 'admin/games',
        query: {
          page: 0,
          perPage: 2147483647,
        }
      }),
  });
}
