import { Form, Input, message, Modal } from "antd";
import React, { useEffect } from "react";
import { SnackDTO } from "../../../../../models/snack.ts";
import { useUpdateSnackMutation } from "../hooks";

interface OwnProps {
  snack: SnackDTO;
  open: boolean;
  onClose: () => void;
}

export default function EditSnackModal({ snack, open, onClose }: OwnProps) {
  const [form] = Form.useForm();
  const updateMutation = useUpdateSnackMutation();

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        updateMutation.mutate({ snackId: snack.id, ...values });
        onClose();
      })
      .catch(() => {
        message.error("Please fill out the form correctly");
      });
  };


  useEffect(() => {
    if (open) {
      form.resetFields();
    }
  }, [open]);

  return (
    <Modal
      title="Edit Snack"
      open={open}
      onOk={handleOk}
      onCancel={onClose}
      destroyOnClose
      className="modal-custom"
      okButtonProps={{ className: "bg-blue-500 hover:bg-blue-600 border-none" }}
      cancelButtonProps={{ className: "hover:border-gray-300" }}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={snack ?? {}}
      >
        <Form.Item
          name="name"
          label="Snack Name"
          rules={[{ required: true, message: "Please input the snack name" }]}
          className="mb-4"
        >
          <Input className="rounded-md" />
        </Form.Item>
      </Form>
      <div className="flex flex-row gap-1 items-center">
        <span className="text-gray-500">Snack Internal ID:</span>
        <span className="text-nowrap text-black">{snack.id}</span>
      </div>
    </Modal>
  );
}
