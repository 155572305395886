import React from "react";
import styles from "./google.module.css";

export function GoogleSignIn({ successfulUrl }: { successfulUrl: string }) {
  const targetUrl = `/login/oauth2/authorization/google?successUrl=${encodeURIComponent(successfulUrl)}`;

  return (
    <a className={styles.link} href={targetUrl}>
      <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M6.02153 0.893896C6.68575 0.684672 7.37418 0.52981 8.07265 0.518847C10.1238 0.369923 12.2069 1.11134 13.7436 2.46764C13.9839 2.66407 13.9862 3.06196 13.7505 3.26296C13.279 3.73577 12.8062 4.20767 12.3339 4.68048C12.2041 4.81661 12.0077 4.90203 11.8195 4.85407C11.6742 4.82483 11.5618 4.72205 11.4481 4.6348C10.3156 3.75587 8.77798 3.43107 7.38605 3.76136C6.09417 4.05692 4.95714 4.91802 4.29201 6.06007C3.40487 5.54707 2.51361 5.04137 1.62738 4.52607L1.6123 4.49775C1.6187 4.38766 1.71006 4.29903 1.76168 4.20356C2.74339 2.65357 4.27557 1.46081 6.02153 0.893896Z"
          fill="#F95440"
        />
        <path
          d="M1.59878 4.52932L1.62756 4.52612C2.51379 5.04142 3.40504 5.54712 4.29219 6.06012C3.75588 7.01442 3.49915 8.14871 3.68964 9.23548C3.77415 9.79554 3.96648 10.3382 4.23828 10.8344C4.25427 10.8759 4.30544 10.9285 4.24605 10.9595C3.36302 11.4821 2.46811 11.986 1.57776 12.4967C0.585094 10.801 0.25801 8.73115 0.680569 6.81251C0.844567 6.06104 1.11729 5.33332 1.48914 4.65997C1.51746 4.61018 1.55949 4.56998 1.59878 4.52932Z"
          fill="#FFBF37"
        />
        <path
          d="M9.12204 7.17244C9.24584 7.09524 9.39704 7.11077 9.53592 7.10712C11.5605 7.1094 13.5856 7.10757 15.6103 7.10803C15.793 7.11625 15.9963 7.07103 16.1598 7.17655C16.2749 7.24508 16.3626 7.35882 16.3951 7.48993C16.5198 7.98649 16.507 8.50316 16.4928 9.01114C16.4133 10.4232 15.9497 11.8137 15.1502 12.9823C14.4723 13.9841 13.5518 14.8096 12.5116 15.4222C11.9886 14.5172 11.4628 13.6131 10.9452 12.7054C11.8972 12.1559 12.6409 11.2729 13.0448 10.2523C12.1795 10.2194 11.3129 10.2391 10.4473 10.2336C10.0672 10.2496 9.68667 10.2583 9.30705 10.2286C9.12615 10.2112 8.96535 10.0833 8.89363 9.91839C8.83242 9.79048 8.84749 9.64475 8.8516 9.50816C8.85982 8.88415 8.82556 8.26013 8.84703 7.63611C8.85069 7.4479 8.9553 7.26289 9.12204 7.17244Z"
          fill="#2987F1"
        />
        <path
          d="M1.57812 12.4966C2.46847 11.9859 3.36338 11.482 4.24641 10.9594L4.28798 10.932C4.90058 11.9708 5.89325 12.794 7.05357 13.1426C7.74657 13.3733 8.49484 13.4185 9.21433 13.3038C9.82601 13.2284 10.4016 12.9863 10.9452 12.7054C11.4628 13.6131 11.9886 14.5171 12.5117 15.4221C11.3655 16.0991 10.0412 16.4728 8.71137 16.4993C8.23902 16.5057 7.76804 16.4659 7.2998 16.4102C5.83843 16.1832 4.43873 15.5509 3.31861 14.5825C2.62059 13.9977 2.04134 13.2787 1.57812 12.4966Z"
          fill="#00A555"
        />
      </svg>
      Sign in with Google
    </a>
  );
}
