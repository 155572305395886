import React, { useEffect, useRef, useState } from "react";
import "./styles.css";

interface OwnProp {
  file: any;
}

export default function SnackImageCardFromBlob({ file }: OwnProp) {
  const [shouldAnimate, setShouldAnimate] = useState(false);
  const textRef = useRef<HTMLSpanElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (textRef.current && containerRef.current) {
      const textWidth = textRef.current.offsetWidth;
      const containerWidth = containerRef.current.clientWidth;
      if (textWidth > containerWidth) {
        setShouldAnimate(true);
      } else {
        setShouldAnimate(false);
      }
    }
  }, [file, textRef.current, containerRef.current]);

  return (
    <div
      title={file.name}
      key={file.uid}
      ref={containerRef}
      className="flex flex-col items-center justify-center w-[120px] h-[200px] border border-gray-300 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 ease-in-out"
    >
      <div className="relative w-[118px] h-[160px] overflow-hidden">
        <img
          src={URL.createObjectURL(file.originFileObj)}
          alt={file.name}
          className="object-cover w-full h-full"
        />
      </div>
      <div className="relative w-full mt-2 text-sm text-gray-600 font-medium px-2 overflow-hidden">
        <span
          className={shouldAnimate ? " animate-scrollText" : " text-nowrap"}
          ref={textRef}
        >
          {file.name}
        </span>
        {shouldAnimate && <div className=" fade-mask"></div>}
      </div>
    </div>
  );
}
