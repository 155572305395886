import React from 'react';
import { Route, Router, Switch } from 'wouter';
import { HomePage } from './homePage.tsx';
import { GamePage } from './gamePage.tsx';
import { HistoryPage } from './historyPage.tsx';

export function MainPage() {
    return (
        <div className="flex flex-col space-y-4">
            <Router base="/">
                <Switch>
                    <Route path="game/:name" component={GamePage}/>
                    <Route path="history/:name" component={HistoryPage}/>
                    <Route component={HomePage}/>
                </Switch>
            </Router>
        </div>
    );
}


