import { useLocation } from "wouter";
import { useMemo } from "react";
import { Menu } from "antd";

const routes = [
  "/admin/games",
  "/admin/snacks",
  "/admin/users"
] as const;
const routeToTitle: Record<keyof typeof routes, string> = {
  // @ts-expect-error wtf?
  "/admin/games": "Game management",
  "/admin/snacks": "Snack management",
  "/admin/users": "User management"
};
const menuItems = routes.map(r => ({ key: r, label: routeToTitle[r] }));

export function NavBar() {
  const [location, navigate] = useLocation();
  const currentRoute = useMemo(() => routes.find(route => location.startsWith(route)) || routes[0], [location]);

  return (
    <Menu
      className="bg-bg"
      selectable
      multiple={false}
      mode="horizontal"
      items={menuItems}
      selectedKeys={[currentRoute]}
      onClick={info => navigate(info.key)}
    />
  );
}
