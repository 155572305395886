import { Button, Form, message, Modal, Select, Spin, Upload, UploadProps } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { useBulkAddSnacksMutation, useGamesForAdmin } from "../hooks";
import SnackImageCardFromBlob from "./snackImageCardFromBlob.tsx";

interface OwnProps {
  gameIdFilter?: string;
  open: boolean;
  onClose: () => void;
}

export default function BulkAddSnackModal({ gameIdFilter, open, onClose }: OwnProps) {
  const [bulkForm] = Form.useForm();
  const [bulkFileList, setBulkFileList] = useState<any[]>([]);
  const bulkAddMutation = useBulkAddSnacksMutation();
  const { isPending, isSuccess } = bulkAddMutation;

  const handleBulkOk = () => {
    bulkForm
      .validateFields()
      .then((values) => {
        if (bulkFileList.length === 0) {
          message.error("Please upload images for the snacks");
          return;
        }

        const formData = new FormData();
        formData.append("bulkSnackRequest", new Blob([JSON.stringify({ gameId: values.gameId })], { type: "application/json" }));

        bulkFileList.forEach((file) => {
          formData.append("files", file.originFileObj);
        });

        bulkAddMutation.mutate(formData);
        // onClose();
      })
      .catch(() => {
        message.error("Please fill out the form correctly");
      });
  };

  const { data: gamesData } = useGamesForAdmin();

  const addSnackGameOptions = gamesData?.content?.map((game) => ({
    label: game.publicId,
    value: game.id
  }));

  const bulkUploadProps: UploadProps = {
    beforeUpload: () => false, // Prevent automatic upload
    multiple: true,
    fileList: bulkFileList,
    onChange: ({ fileList }) => setBulkFileList(fileList),
    accept: "image/*",
    showUploadList: false // We'll render the list ourselves
  };

  useEffect(() => {
    if (open) {
      bulkForm.resetFields();
      setBulkFileList([]);
    }
  }, [open]);

  useEffect(() => {
    if (isSuccess) {
      onClose();
    }
  }, [isSuccess, onClose]);

  const _gameIdFilter = addSnackGameOptions?.find((game) => game.label === gameIdFilter)?.value;

  return (
    <Modal
      title="Add Multiple Snacks"
      open={open}
      onOk={handleBulkOk}
      onCancel={onClose}
      destroyOnClose
      className="modal-custom"
      okButtonProps={{ className: "bg-green-500 hover:bg-green-600 border-none" }}
      cancelButtonProps={{ className: "hover:border-gray-300" }}
      width={800}
      confirmLoading={isPending}
      closable={!isPending}
      maskClosable={!isPending}
    >
      <Spin spinning={isPending}>
        <Form form={bulkForm} layout="vertical">
          <Form.Item
            name="gameId"
            label="Game"
            rules={[{ required: true, message: "Please select a game" }]}
            className="mb-4"
            initialValue={_gameIdFilter}
          >
            <Select
              showSearch
              placeholder="Select a game"
              optionFilterProp="label"
              options={addSnackGameOptions}
              filterOption={(input, option) =>
                option?.label.toLowerCase().includes(input.toLowerCase()) ?? false
              }
              className="w-full"
            />
          </Form.Item>
          <Form.Item
            label="Images"
            required
            className="mb-0"
          >
            <Upload {...bulkUploadProps}>
              <Button icon={<UploadOutlined />}>Select Images</Button>
            </Upload>
            {bulkFileList.length === 0 && (
              <div className="text-red-500 mt-2">Please upload images</div>
            )}
            {bulkFileList.length > 0 && (
              <div className="grid grid-cols-4 gap-2 mt-4">
                {bulkFileList.map((file) => (
                  <SnackImageCardFromBlob file={file} />
                ))}
              </div>
            )}
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
}
